.fileUploadCont {
    background-color: #fff;
    border-radius: 4px;
    border: solid #ccc;
    margin: 40px auto;
    padding: 30px;
    width: 550px;
}

.header {
    color: #3f4c6b;
    font-size: 18px;
    font-weight: 450;
}

form {
    background: #FFFFFF;
    border: 1px dashed rgba(0, 0, 0, 0.38);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 50px 0;
}

form button {
    display: block !important;
    margin: 20px auto;
}
.root{
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}
.head{
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.root .pageTitle{
    font-size: 20px;
    float: left;
}
.btnTop{
    background: #183C73 !important;
    float: right;
    text-transform: none;
}
.fileTitle{
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
}
.fileSubTitle{
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 8px !important;
}
/* .fileLabelBtn{
    background: #000;
} */

label.fileLabelBtn input[type="file"] {
    position:absolute;
    top: -1000px;
}

.fileLabelBtn {
    background: #FFFFFF;
    border: 1px solid #183C73;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 9px 16px;
    margin-top: 16px;
    display: inline-block;
}
.fileLabelBtn:hover {
    background: #ffffff;
}
.fileLabelBtn:active {
    background: #ffffff;
}
.fileLabelBtn :invalid + span {
    color: #183C73;
}
.fileLabelBtn :valid + span {
    color: #183C73;
}
.fileLabelBtn span {
    color: #183C73;
}
.desktop{
    display: block;
    text-transform: none;
}
.mobile{
    display: none;
}
@media(max-width:1024px){
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
}
.dropzoneContainer {
    background-color: #fff;
    border-radius: 4px;
    padding: 30px 25px;
}
.dropzoneContainerNoPadding {
    background-color: #fff;
    border-radius: 4px;
    padding: 0px;
}
.dropzoneRoot {
    margin-bottom: 15px;
}